.awssld--cubeAnimation {
  --cube-animation-duration: 675ms;
  --cube-animation-perspective: 1800px;
  --cube-animation-ease-in: cubic-bezier(0.8, 0, 1, 0.8);
  --cube-animation-ease-out: cubic-bezier(0, 0.2, 0.2, 1);
  --cube-translateZ-distance: -225px; }
  .awssld--cubeAnimation .awssld__container {
    -webkit-perspective: var(--cube-animation-perspective);
            perspective: var(--cube-animation-perspective); }
  .awssld--cubeAnimation .awssld--moveRight {
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-animation: cubeLeftIn var(--cube-animation-duration) both var(--cube-animation-ease-in);
            animation: cubeLeftIn var(--cube-animation-duration) both var(--cube-animation-ease-in); }
  .awssld--cubeAnimation .awssld--moveLeft {
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-animation: cubeRightIn var(--cube-animation-duration) both var(--cube-animation-ease-in);
            animation: cubeRightIn var(--cube-animation-duration) both var(--cube-animation-ease-in); }
  .awssld--cubeAnimation .awssld--exit.awssld--moveLeft {
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-animation: cubeLeftOut var(--cube-animation-duration) both var(--cube-animation-ease-in);
            animation: cubeLeftOut var(--cube-animation-duration) both var(--cube-animation-ease-in); }
  .awssld--cubeAnimation .awssld--exit.awssld--moveRight {
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-animation: cubeRightOut var(--cube-animation-duration) both var(--cube-animation-ease-in);
            animation: cubeRightOut var(--cube-animation-duration) both var(--cube-animation-ease-in); }

@-webkit-keyframes cubeLeftOut {
  50% {
    -webkit-animation-timing-function: var(--cube-animation-ease-out);
            animation-timing-function: var(--cube-animation-ease-out);
    -webkit-transform: translateX(-50%) translateZ(var(--cube-translateZ-distance)) rotateY(-45deg);
            transform: translateX(-50%) translateZ(var(--cube-translateZ-distance)) rotateY(-45deg); }
  100% {
    -webkit-transform: translateX(-100%) rotateY(-90deg);
            transform: translateX(-100%) rotateY(-90deg); } }

@keyframes cubeLeftOut {
  50% {
    -webkit-animation-timing-function: var(--cube-animation-ease-out);
            animation-timing-function: var(--cube-animation-ease-out);
    -webkit-transform: translateX(-50%) translateZ(var(--cube-translateZ-distance)) rotateY(-45deg);
            transform: translateX(-50%) translateZ(var(--cube-translateZ-distance)) rotateY(-45deg); }
  100% {
    -webkit-transform: translateX(-100%) rotateY(-90deg);
            transform: translateX(-100%) rotateY(-90deg); } }

@-webkit-keyframes cubeRightOut {
  50% {
    -webkit-animation-timing-function: var(--cube-animation-ease-out);
            animation-timing-function: var(--cube-animation-ease-out);
    -webkit-transform: translateX(50%) translateZ(var(--cube-translateZ-distance)) rotateY(45deg);
            transform: translateX(50%) translateZ(var(--cube-translateZ-distance)) rotateY(45deg); }
  100% {
    -webkit-transform: translateX(100%) rotateY(90deg);
            transform: translateX(100%) rotateY(90deg); } }

@keyframes cubeRightOut {
  50% {
    -webkit-animation-timing-function: var(--cube-animation-ease-out);
            animation-timing-function: var(--cube-animation-ease-out);
    -webkit-transform: translateX(50%) translateZ(var(--cube-translateZ-distance)) rotateY(45deg);
            transform: translateX(50%) translateZ(var(--cube-translateZ-distance)) rotateY(45deg); }
  100% {
    -webkit-transform: translateX(100%) rotateY(90deg);
            transform: translateX(100%) rotateY(90deg); } }

@-webkit-keyframes cubeLeftIn {
  0% {
    -webkit-transform: translateX(100%) rotateY(90deg);
            transform: translateX(100%) rotateY(90deg); }
  50% {
    -webkit-animation-timing-function: var(--cube-animation-ease-out);
            animation-timing-function: var(--cube-animation-ease-out);
    -webkit-transform: translateX(50%) translateZ(var(--cube-translateZ-distance)) rotateY(45deg);
            transform: translateX(50%) translateZ(var(--cube-translateZ-distance)) rotateY(45deg); } }

@keyframes cubeLeftIn {
  0% {
    -webkit-transform: translateX(100%) rotateY(90deg);
            transform: translateX(100%) rotateY(90deg); }
  50% {
    -webkit-animation-timing-function: var(--cube-animation-ease-out);
            animation-timing-function: var(--cube-animation-ease-out);
    -webkit-transform: translateX(50%) translateZ(var(--cube-translateZ-distance)) rotateY(45deg);
            transform: translateX(50%) translateZ(var(--cube-translateZ-distance)) rotateY(45deg); } }

@-webkit-keyframes cubeRightIn {
  0% {
    -webkit-transform: translateX(-100%) rotateY(-90deg);
            transform: translateX(-100%) rotateY(-90deg); }
  50% {
    -webkit-animation-timing-function: var(--cube-animation-ease-out);
            animation-timing-function: var(--cube-animation-ease-out);
    -webkit-transform: translateX(-50%) translateZ(var(--cube-translateZ-distance)) rotateY(-45deg);
            transform: translateX(-50%) translateZ(var(--cube-translateZ-distance)) rotateY(-45deg); } }

@keyframes cubeRightIn {
  0% {
    -webkit-transform: translateX(-100%) rotateY(-90deg);
            transform: translateX(-100%) rotateY(-90deg); }
  50% {
    -webkit-animation-timing-function: var(--cube-animation-ease-out);
            animation-timing-function: var(--cube-animation-ease-out);
    -webkit-transform: translateX(-50%) translateZ(var(--cube-translateZ-distance)) rotateY(-45deg);
            transform: translateX(-50%) translateZ(var(--cube-translateZ-distance)) rotateY(-45deg); } }

